<!--工资条发送历史-->
<template>
  <div>
    <div>

      <div style="margin-right: 50px">
        <el-select style="margin-left: 5px;margin-bottom: 20px;margin-right: 20px" v-model="unit"
                   placeholder="请选择标段" size="small">
          <el-option v-for="item in bdsts" :key="item.bdstname" :label="item.bdstname" :value="item.bdstname">
          </el-option>
        </el-select>
        <el-date-picker
            v-model="month"
            type="month"
            format="YYYY-MM"
            value-format="YYYY-MM"
            placeholder="选择月份"
            size="small" >

        </el-date-picker>
        <el-input style="width: 250px;margin-left: 20px; margin-right: 20px" v-model="username" placeholder="请输入姓名或首字母" clearable  size="small"/>
        <el-button type="warning" size="small" @click="getMailSendStatusByMonth">查询</el-button>
      </div>

    </div>
    <!--    表格数据-->
    <div>
      <vxe-table
          border="inner"
          :align="allAlign"
          show-overflow
          auto-resize
          height="700"
          :data="mailSendStatusByMonth"
          :edit-config="{trigger: 'dblclick', mode: 'cell'}">
        <vxe-column type="seq" title="序号" width="60px" fixed="left"></vxe-column>
        <vxe-column field="bdst" title="单位" fixed="left"></vxe-column>
        <vxe-column field="username" title="姓名" fixed="left"></vxe-column>
        <vxe-column field="month"  title="工资月份"></vxe-column>
        <vxe-column field="userId" width="60px" title="工号"></vxe-column>
        <vxe-column field="target_address"  title="邮箱地址"></vxe-column>
        <vxe-column field="createTime"  title="发送时间"></vxe-column>
        <vxe-column field="msgId"  title="消息ID"></vxe-column>
        <vxe-column  title="邮件发送状态">
          <template #default="{ row }">
            <el-tag v-if="row.msg_status === 0" type="info" size="mini">发送中</el-tag>
            <el-tag v-if="row.msg_status === 1" type="success" size="mini">发送成功</el-tag>
            <el-tag v-if="row.msg_status === 2" type="danger" size="mini">发送失败</el-tag>
          </template>
        </vxe-column>
        <vxe-column title="失败原因" field="errorInfo">
        </vxe-column>
      </vxe-table>
    </div>
    <!--    分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {ElMessage} from 'element-plus'
import {setNowMonth2} from "@/utils/time";

export default {
  name: "salarySendHistory",
  data() {
    return {
      bdsts: [],
      username: '',
      month:'',
      totals: 0,
      mail_send_logs: [],
      allAlign: 'center',
      //发送邮件历史
      mailSendStatusByMonth:[],
      unit:'',
      pagenum:1

    }
  },
  created() {
    this.axios.get('/other/exzxzz', (response) => {
      this.bdsts = response.obj;
      console.log(this.bdsts)
    }, {
      bdst: 1
    });
    // 默认获取当月月份
    this.month = setNowMonth2(new Date());
  },
  methods: {
    getMailSendStatusByMonth() {
      if (this.month !== null & this.month !== "" & this.unit !== "") {
        this.axios.post('/salary/getMailSendStatusByMonth', (response) => {
          console.log(response)
          this.mailSendStatusByMonth = response.obj;
        }, {
          bdst: this.unit,
          userName: this.username,
          month: this.month,
          pageCount:this.pagenum
        });
      } else {
        ElMessage.error('请选择单位和月份!')
      }
    },
  },
  watch: {
    pagenum() {
      this.axios.post('/user/getsaluser', (response) => {
        //this.bdsts = response.obj;
        console.log(response)
        this.totals = response.obj.pages * 10;
        this.users = response.obj.records;
      }, {
        bdst: this.oper,
        name: this.username,
        pageCount: this.pagenum
      });
    }
  }
}
</script>

<style scoped>

</style>