<template>
  <div>
    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="上传工资表" name="a"><Salaryup></Salaryup></el-tab-pane>
        <el-tab-pane label="查询工资表" name="b"><Salarysel></Salarysel></el-tab-pane>
        <el-tab-pane label="财务用户管理" name="c"><SalaryUser></SalaryUser></el-tab-pane>
        <el-tab-pane label="邮件发送历史" name="d"><salarySendHistory></salarySendHistory></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Salaryup from '@/views/salary_post/salaryup'
import Salarysel from '@/views/salary_post/salarysel'
import SalaryUser from  '@/views/salary_post/salaryuser'
import salarySendHistory from "@/views/salary_post/salarySendHistory";
import Header from "@/views/header";
import {ElMessage} from "element-plus";
import { mapMutations } from 'vuex'
export default {
  name: "salarypost",
  components: {Header, Salaryup, Salarysel, SalaryUser,salarySendHistory},
  data(){
    return{
      activeName: 'a'
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
    // if (sessionStorage.getItem('formsnames')){
    //   this.activeName = sessionStorage.getItem('formsnames');
    // }
  },
  methods:{
    ...mapMutations(['SET_TOKEN']),
    handleClick(tab, event){
      console.log(tab)
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('formsnames', tab.props.name)
    },
    reall(){
      sessionStorage.removeItem('formsnames');
    },
  }
}
</script>

<style scoped>
#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
  background: #f2f2f2;
}
</style>
